import A11yDialog from "a11y-dialog";
import objectAssign from "object-assign";

const defaultConfig = () => ({
  /** Class which indicates that modal is open */
  classModalIsOpen: "is-active",
  /** Class on body which indicates that modal is open */
  classModalIsOpenBody: "has-modal",
  /** Root of page content which should be hidden when modal is open */
  root: "#root",
  /** On show callback function. */
  onShow: () => {},
  /** On hide callback function. */
  onHide: () => {},
  /** Should portal modal into this selector (must be unique in DOM) */
  portal: "#root-modals",
});

export default class Modal {
  constructor(element, config) {
    this.element = element;
    this.config = objectAssign(defaultConfig(), config);

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    Modal.portal = Modal.portal.bind(this);
    Modal.lockBody = Modal.lockBody.bind(this);
    Modal.unlockBody = Modal.unlockBody.bind(this);

    this.element.L_Modal = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.L_Modal ? el.L_Modal : null;
  }

  handleShow(el) {
    this.constructor.lockBody();
    if (el) el.classList.add(this.config.classModalIsOpen);

    // Long modals can have first focusabble element under the fold, so static content above the fold should be focused instead to prevent undesirable scrolling
    const initialFocusEl = el.querySelector("[data-modal-initial-focus]");

    if (initialFocusEl) {
      // set tabindex to -1 so element can't be focuses via keyboard
      if (initialFocusEl.tabIndex === -1) {
        initialFocusEl.setAttribute("tabindex", "-1");
      }

      // focus element
      initialFocusEl.focus();
    }

    // close button is initialy disabled, because it's first in the DOM and we don't want to be focused after modal opens
    const closeButton = el.querySelector(".modal-dialog__close");
    if (closeButton) {
      // close button should be actionable
      closeButton.removeAttribute("disabled");
    }

    this.config.onShow();
  }

  handleHide(el) {
    this.constructor.unlockBody();
    if (el) el.classList.remove(this.config.classModalIsOpen);

    // disable close button to restore initial button state
    const closeButton = el.querySelector(".modal-dialog__close");
    if (closeButton) {
      closeButton.setAttribute("disabled", "disabled");
    }

    this.config.onHide();
  }

  show() {
    this.instance.show();
  }

  hide() {
    this.instance.hide();
  }

  init() {
    if (this.config.portal) {
      Modal.portal(this.element, document.querySelector(this.config.portal));
    }

    this.instance = new A11yDialog(
      this.element,
      document.querySelector(this.config.root)
    );

    this.instance.on("show", this.handleShow);

    this.instance.on("hide", this.handleHide);
  }

  destroy() {
    this.instance.destroy();
    this.element.L_Modal = this;
  }

  update() {
    this.destroy();
    this.init();
  }

  static portal(el, container) {
    if (container) {
      container.appendChild(el);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `\`portal: ${this.config.portal}\` element is not present in DOM. Modal will be placed inside content which can affect it's styleing. Please provide \`portal\` selector (should be placed outside of main contant, usualy in end of <body /> tag)`
      );
    }
  }

  static lockBody(
    className = this.config.classModalIsOpenBody,
    root = this.config.root
  ) {
    const container = document.querySelector(root);
    // store current scrollTop value
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    document.body.setAttribute("data-lock-scrolltop", scrollTop);

    // add locking styles to body
    document.body.style.height = "100%";
    document.body.style.width = "100%";
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

    // add locking styles to scrollTop
    if (container) {
      /* eslint-disable no-param-reassign */
      container.style.height = "100%";
      container.style.width = "100%";
      container.style.overflow = "hidden";
      container.style.position = "fixed";
      // scroll page-container to scrollTop position
      container.scrollTop = scrollTop;
      /* eslint-enable no-param-reassign */
    }

    // add modal class
    document.body.classList.add(className);

    // attempt to scroll top fixed position
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollTop);
    });
  }

  static unlockBody(
    className = this.config.classModalIsOpenBody,
    root = this.config.root
  ) {
    const container = document.querySelector(root);
    const scrollTop = document.body.getAttribute("data-lock-scrolltop");

    // remove locking styles from body
    document.body.style.height = "";
    document.body.style.width = "";
    document.body.style.overflow = "";
    document.body.style.position = "";

    // add modal class
    document.body.classList.remove(className);

    // remove locking styles from page-container
    if (container) {
      /* eslint-disable no-param-reassign */
      container.style.height = "";
      container.style.width = "";
      container.style.overflow = "";
      container.style.position = "";
      /* eslint-enable no-param-reassign */
    }

    // set scroll position back
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollTop);
    });
  }
}
