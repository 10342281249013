import { createPopper } from "@popperjs/core";

import { keyCodes } from "../../utils";

const defaultConfig = {
  portal: "#root-tooltips",
  popperOptions: {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  },
};

export default class Tooltip {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.handleVisibility = this.handleVisibility.bind(this);
    this.onKeydown = this.onKeydown.bind(this);

    this.element.L_Tooltip = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.L_Tooltip ? el.L_Tooltip : null;
  }

  init() {
    this.trigger = document.querySelector(
      `[aria-describedby=${this.element.getAttribute("id")}]`
    );

    if (!this.trigger) {
      return;
    }

    if (this.config.portal) {
      Tooltip.portal(this.element, document.querySelector(this.config.portal));
    }

    if (!this.trigger) {
      // eslint-disable-next-line no-console
      console.warn(
        `Tooltip trigger element is not defined and the tooltip cannot be attached. Please provide an element with [aria-describedby=${this.element.getAttribute(
          "id"
        )}]`
      );
    }
    this.instance = createPopper(
      this.trigger,
      this.element,
      this.config.popperOptions
    );

    this.trigger.addEventListener("mouseenter", this.handleVisibility);
    this.trigger.addEventListener("mouseleave", this.handleVisibility);
    this.trigger.addEventListener("focus", this.handleVisibility);
    this.trigger.addEventListener("blur", this.handleVisibility);
  }

  handleVisibility(e) {
    if (e.type === "mouseenter" || e.type === "focus") {
      this.show();
    }

    if (e.type === "blur") {
      this.hide();
    }

    if (e.type === "mouseleave") {
      const shouldClose = this.shouldClose(e);

      if (shouldClose) {
        this.hide();
      }
    }
  }

  shouldClose(e) {
    const mouseLeftTo = e.relatedreference || e.toElement || e.relatedTarget;

    const callback = (e2) => {
      const mouseLeftTo2 =
        e2.relatedreference || e2.toElement || e2.relatedTarget;

      this.element.removeEventListener("mouseleave", callback);

      if (!this.trigger.contains(mouseLeftTo2)) {
        this.hide();
      }
    };

    if (this.element.contains(mouseLeftTo)) {
      this.element.addEventListener("mouseleave", callback);
      return false;
    }

    return true;
  }

  onKeydown(e) {
    if (e.which === keyCodes.ESC) {
      this.hide();
    }
  }

  show() {
    this.element.setAttribute("aria-hidden", "false");
    document.addEventListener("keydown", this.onKeydown);
  }

  hide() {
    this.element.setAttribute("aria-hidden", "true");
    document.removeEventListener("keydown", this.onKeydown);
  }

  update() {
    this.instance.update();
  }

  rootContainerdestroy() {
    this.trigger.removeEventListener("mouseenter", this.handleVisibility);
    this.trigger.removeEventListener("mouseleave", this.handleVisibility);
    this.trigger.removeEventListener("focus", this.handleVisibility);
    this.trigger.removeEventListener("blur", this.handleVisibility);

    this.instance.destroy();
    this.element.L_Tooltip = null;
  }

  static portal(el, container) {
    if (container) {
      container.appendChild(el);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `\`portal: ${this.config.portal}\` element is not present in DOM. Tooltip will be placed inside content which can affect it's styleing. Please provide \`portal\` selector (should be placed outside of main contant, usualy in end of <body /> tag)`
      );
    }
  }
}
