// eslint-disable-next-line import/no-cycle
import { TOGGLE_EVT } from "./Toggle";

export default function toggle(props) {
  const {
    element,
    attribute,
    value,
    defaultValue,
    dispatchEvent = true,
    eventDetail,
  } = props;
  let returnValue = null;

  if (!element || !(element instanceof Element)) {
    return returnValue;
  }

  if (attribute === "class") {
    element.classList.toggle(value);
    returnValue = value;
  }

  if (attribute === "text") {
    const oldText = element.textContent;
    element.textContent = value;
    returnValue = oldText;
  }

  if (attribute !== "class" && attribute !== "text") {
    returnValue = element.getAttribute(attribute);

    if (!value) {
      element.setAttribute(attribute, !(returnValue === "true"));
    } else if (typeof defaultValue !== "undefined") {
      if (value === returnValue) {
        element.setAttribute(attribute, defaultValue);
      } else {
        element.setAttribute(attribute, value);
      }
    } else {
      element.setAttribute(attribute, value);
    }
  }

  if (dispatchEvent) {
    element.dispatchEvent(
      new CustomEvent(TOGGLE_EVT, {
        detail: eventDetail,
        bubbles: true,
      })
    );
  }

  return returnValue;
}
