import { moduleFactory } from "../scripts/lib";

import Dropdown from "./Dropdown/Dropdown.static";
import Modal from "./Modal/Modal.static";
import Select from "./Forms/Select/Select.static";
import Tabs from "./Tabs/Tabs.static";
import Tooltip from "./Tooltip/Tooltip.static";

window.myApp = window.myApp || {};

window.addEventListener("DOMContentLoaded", () => {
  window.myApp.selects = moduleFactory(Select, "[data-select]");
  window.myApp.tabs = moduleFactory(Tabs, '[role="tablist"]');
  window.myApp.modals = moduleFactory(Modal, "[data-modal]");
  window.myApp.dropdown = moduleFactory(Dropdown, "[data-dropdown]");
  window.myApp.tooltip = moduleFactory(Tooltip, "[data-tooltip]");
});
