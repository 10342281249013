import { colors } from "../tokens";

import { isObject } from "./helpers";

export function color(colorName, weight = 500, colorPalette = colors) {
  if (!isObject(colorPalette)) {
    throw new Error(
      `colorPalette ${JSON.stringify(colorPalette)} is not an object`
    );
  }

  if (typeof colorPalette[colorName] === "string") {
    return colorPalette[colorName];
  }

  if (!colorPalette[colorName][weight]) {
    throw new Error(
      `color ${colorName} of weight ${weight} not exists in colorPalette ${JSON.stringify(
        colorPalette
      )}`
    );
  }

  return colorPalette[colorName][weight];
}

// eslint-disable-next-line no-shadow
export function getPaletteFromColors(color) {
  if (isObject(color)) {
    return Object.keys(color).reduce((acc, shade) => {
      acc[shade] = color[shade];
      return acc;
    }, {});
  }
  return color;
}
