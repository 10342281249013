export function isObject(val) {
  return val != null && typeof val === "object" && Array.isArray(val) === false;
}

// Get element position relative to document
export function windowOffset(el) {
  const position = { x: 0, y: 0 };

  /* eslint-disable no-cond-assign, no-param-reassign */
  if (el.offsetParent) {
    do {
      position.x += el.offsetLeft;
      position.y += el.offsetTop;
    } while ((el = el.offsetParent));
  }
  /* eslint-enable */

  return position;
}

// Get element siblings
export function getSiblings(el) {
  if (!(el instanceof Element)) {
    return false;
  }

  const parent = el.parentNode;
  if (!parent) {
    return false;
  }

  const parentChildren = [];
  const siblings = [];

  parentChildren.push.apply(parentChildren, parent.children); // eslint-disable-line prefer-spread

  for (let i = 0, l = parentChildren.length; i < l; i += 1) {
    if (parentChildren[i] !== el) {
      siblings.push(parentChildren[i]);
    }
  }

  return siblings;
}
