import Choices from "choices.js";

const defaultConfig = {
  searchEnabled: false,
  searchChoices: false,
  shouldSort: false,
};

export default class Select {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.handleShowDropdown = this.handleShowDropdown.bind(this);
    this.handleHideDropdown = this.handleHideDropdown.bind(this);

    this.element.L_Select = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.L_Select ? el.L_Select : null;
  }

  // eslint-disable-next-line class-methods-use-this
  handleShowDropdown(e) {
    const container = e.target.parentNode.parentNode;
    if (container.classList.contains("is-flipped")) {
      container.classList.add("is-flipped-helper");
    }

    const searchInput = container.querySelector(
      ".choices__list--dropdown .choices__input"
    );

    if (searchInput) {
      // search input is not focused by default - perhaps due animation?
      // timeout fixes this issue
      setTimeout(() => {
        searchInput.focus();
      }, 100);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleHideDropdown(e) {
    const dropdown = e.target.parentNode.nextSibling;
    const container = e.target.parentNode.parentNode;

    const transitionEndHandler = (evt) => {
      if (evt.propertyName === "visibility") {
        container.classList.remove("is-flipped");
        container.classList.remove("is-flipped-helper");

        dropdown.removeEventListener("transitionend", transitionEndHandler);
      }
    };

    if (container.classList.contains("is-flipped-helper")) {
      container.classList.add("is-flipped");

      dropdown.addEventListener("transitionend", transitionEndHandler);
    }
  }

  init() {
    const config = { ...this.config };

    if (this.instance) {
      return;
    }

    if (
      (this.element.multiple && config.removeItemButton === undefined) ||
      this.element.hasAttribute("data-select-clearable")
    ) {
      config.removeItemButton = true;
    }

    if (this.element.hasAttribute("placeholder")) {
      config.placeholderValue = this.element.getAttribute("placeholder");
    }

    if (this.element.hasAttribute("data-select-searchable")) {
      config.searchPlaceholderValue = this.element.getAttribute("placeholder");
      config.searchEnabled = true;
      config.searchChoices = true;
      config.shouldSort = true;
    }

    if (this.element.hasAttribute("data-select-position")) {
      config.position = this.element.getAttribute("data-select-position");
    }

    const choice = new Choices(this.element, config);

    choice.containerOuter.element.classList.add("select");

    // disable input if [disabled] or '.disabled'
    if (this.element.disabled || this.element.classList.contains("disabled")) {
      choice.disable();
    }

    const classes = Array.from(this.element.classList);
    const unwantedClasses = ["choices__input", "is-hidden"];

    classes.forEach((className) => {
      if (!unwantedClasses.includes(className)) {
        choice.containerOuter.element.classList.add(className);
      }
    });

    if (config.searchEnabled) {
      const input = choice.dropdown.querySelector("input");

      if (input) {
        input.classList.add("input");
        input.classList.add("input--search");
      }
    }

    this.element.addEventListener("showDropdown", this.handleShowDropdown);
    this.element.addEventListener("hideDropdown", this.handleHideDropdown);

    this.instance = choice;
  }

  update(force) {
    if (force) {
      this.instance.destroy();
    }

    this.init();
  }

  destroy() {
    this.instance.destroy();
    this.instance = null;
    this.element.L_Select = null;
  }
}
