/* eslint-disable import/prefer-default-export */

export const colors = {
  primary: {
    500: "#ff5722",
  },
  secondary: {
    500: "#21a2ff",
  },
  success: {
    500: "#3adb76",
  },
  error: {
    500: "#fa5252",
    50: "#fff5f5",
    100: "#ffe3e3",
    300: "#ff6b6b",
  },
  grey: {
    500: "#adb5bd",
  },
};
