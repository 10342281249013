import Popper from "popper.js";
import tabbable from "tabbable";

import { keyCodes } from "../../utils/constants";
import { TOGGLE_EVT } from "../../scripts/modules/Toggle";

const defaultConfig = {
  /** {element} Anchor of the dropdown menu. If none specified, the module queries for `[aria-controls=dropdown-menu-id]`. */
  anchorElement: null,
  /** {string} Attribute name which enables interactive dropdown behaviour. When enabled, the dropdown does not close upon interaction inside. */
  dataInteractive: "data-dropdown-interactive",
  /** {element} On init, all dropdowns are transported here, so that they can be used in overflowing containers. */
  dropdownRoot: document.getElementById("root-dropdowns"),
  /** {element} Root element of the app. Helps handling clicks outsite the dropdown */
  appRoot: document.getElementById("root"),
  /** {object} Popper.js options */
  popperOptions: {
    placement: "bottom-start",
    removeOnDestroy: true,
  },
  /** {func} On dropdown open callback function. */
  onOpen: () => {},
  /** {func} On dropdown close callback function. */
  onClose: () => {},
};

const isActive = (el) => el.classList.contains("is-active");

export default class Dropdown {
  constructor(element, config) {
    this.element = element;

    this.config = { ...defaultConfig, ...config };
    this.config.popperOptions = {
      ...defaultConfig.popperOptions,
      ...(config.popperOptions || {}),
    };

    this.anchorElement = this.config.anchorElement;

    this.handleToggle = this.handleToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);

    this.focusableDescendants = [];

    this.element.L_Dropdown = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.L_Dropdown ? el.L_Dropdown : null;
  }

  destroy() {
    this.element.removeEventListener(TOGGLE_EVT, this.handleToggle);
    this.instance.destroy();
    this.removeListeners();
    this.element.L_Dropdown = null;
  }

  update() {
    this.destroy();
    this.init();
  }

  init() {
    if (!this.anchorElement) {
      this.anchorElement = document.querySelector(
        `[aria-controls=${this.element.getAttribute("id")}]`
      );
    }

    if (!this.anchorElement) {
      return;
    }

    this.transportDropdown();
    this.adjustWidth();
    this.element.addEventListener(TOGGLE_EVT, this.handleToggle);
    this.instance = new Popper(
      this.anchorElement,
      this.element,
      this.config.popperOptions
    );

    if (this.anchorElement.hasAttribute("data-dropdown-open-on-init")) {
      this.open();
    }
  }

  open() {
    if (!isActive(this.element)) {
      this.anchorElement.click();
    }
  }

  close() {
    if (isActive(this.element)) {
      this.anchorElement.click();
    }
  }

  handleToggle(e) {
    this.trigger = e.detail.trigger;
    this.instance.update();

    // attach events only if dropdown is open
    if (isActive(this.element)) {
      this.adjustWidth();
      this.addListeners();
      this.element.setAttribute("tabindex", "0");

      setTimeout(() => {
        this.element.focus();
        this.focusableDescendants = tabbable(this.element);
      }, 100);
      this.config.onOpen();
    } else {
      this.element.setAttribute("tabindex", "-1");
      this.config.onClose();
    }
  }

  handleClick(e) {
    // cez podmienku prelezie len klik mimo btnu
    if (!this.anchorElement.contains(e.target)) {
      if (
        !this.element.hasAttribute(this.config.dataInteractive) ||
        (!this.element.contains(e.target) &&
          this.config.appRoot.contains(e.target))
      ) {
        // we shouldn't handle tirgger toggle actions here
        this.trigger.click();
      }
    } else {
      this.removeListeners();
    }
  }

  handleKeydown(e) {
    const activeDescendantIndex = this.focusableDescendants.indexOf(
      document.activeElement
    );

    if (e.which === keyCodes.ARROWDOWN) {
      e.preventDefault();
      if (document.activeElement === this.element) {
        this.focusableDescendants[0].focus();
      } else if (
        activeDescendantIndex >= 0 &&
        activeDescendantIndex < this.focusableDescendants.length - 1
      ) {
        this.focusableDescendants[activeDescendantIndex + 1].focus();
      }
    }

    if (e.which === keyCodes.ARROWUP) {
      e.preventDefault();
      if (activeDescendantIndex >= 1) {
        this.focusableDescendants[activeDescendantIndex - 1].focus();
      }
    }

    if (e.which === keyCodes.ESC) {
      e.preventDefault();
      this.trigger.focus();
      this.trigger.click();
    }

    if (e.which === keyCodes.TAB) {
      if (
        !e.shiftKey &&
        activeDescendantIndex === this.focusableDescendants.length - 1
      ) {
        e.preventDefault();
        this.trigger.click();

        setTimeout(() => {
          const focusable = tabbable(this.config.appRoot);
          focusable[focusable.indexOf(this.trigger) + 1].focus();
        }, 100);
      }

      if (
        e.shiftKey &&
        (activeDescendantIndex === 0 || activeDescendantIndex === -1)
      ) {
        e.preventDefault();
        this.trigger.click();
        this.trigger.focus();
      }
    }
  }

  handleFocus() {
    if (
      this.trigger !== document.activeElement &&
      this.element !== document.activeElement &&
      !this.anchorElement.contains(document.activeElement) &&
      this.config.appRoot.contains(document.activeElement)
    ) {
      this.trigger.click();
      this.removeListeners();
    }
  }

  adjustWidth() {
    this.element.style.minWidth = getComputedStyle(this.anchorElement).width;
  }

  transportDropdown() {
    if (this.config.dropdownRoot) {
      this.config.dropdownRoot.appendChild(this.element);
    }
  }

  addListeners() {
    document.addEventListener("click", this.handleClick, true);
    document.addEventListener("focus", this.handleFocus, true);

    if (!this.element.hasAttribute(this.config.dataInteractive)) {
      document.addEventListener("keydown", this.handleKeydown, true);
    }
  }

  removeListeners() {
    document.removeEventListener("click", this.handleClick, true);
    document.removeEventListener("focus", this.handleFocus, true);

    if (this.element) {
      if (!this.element.hasAttribute(this.config.dataInteractive)) {
        document.removeEventListener("keydown", this.handleKeydown, true);
      }
    }
  }
}
