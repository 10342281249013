// eslint-disable-next-line import/prefer-default-export
export const keyCodes = {
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  ARROWLEFT: 37,
  ARROWUP: 38,
  ARROWRIGHT: 39,
  ARROWDOWN: 40,
};

export const colors = {
  primary: [500, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50],
  secondary: [500, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50],
  grey: [500, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50],
  error: [500, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50],
  success: [500, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50],
};
