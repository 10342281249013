// ---------------------------------------------------
// Tabs
// module for handling tabs

import { RovingTabindex } from "../../utils";

const defaultConfig = {
  tabSelector: '[role="tab"]',
  activeClass: "is-active",
  onChange: () => {},
};

// selector = '[role="tab"]'
export default class Tabs {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.tabs = [];
    this.tabPanels = [];
    this.activeTabIndex = null;

    this.rovingTabindex = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleTabFocus = this.handleTabFocus.bind(this);

    this.element.L_Tabs = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.L_Tabs ? el.L_Tabs : null;
  }

  init() {
    this.tabs = Array.from(
      this.element.querySelectorAll(this.config.tabSelector)
    );

    this.tabs.map((tab, index) => {
      this.tabPanels.push(
        document.getElementById(tab.getAttribute("aria-controls"))
      );
      tab.addEventListener("click", this.handleClick);
      tab.addEventListener("focus", this.handleTabFocus);

      if (this.isActive(tab)) {
        this.activeTabIndex = index;
      }

      return tab;
    });

    if (!this.rovingTabindex) {
      this.rovingTabindex = new RovingTabindex(this.tabs, {
        direction: "horizontal",
      });
    } else {
      this.rovingTabindex.update(this.tabs);
    }
  }

  destroy() {
    this.tabs.map((tab) => {
      tab.removeEventListener("click", this.handleClick);
      tab.removeEventListener("focus", this.onFocus);
      return tab;
    });

    this.tabs = [];
    this.tabPanels = [];
    this.rovingTabindex.destroy();
    this.element.L_Tabs = null;
  }

  update() {
    this.destroy();
    this.init();
  }

  isActive = (el) => el.classList.contains(this.config.activeClass);

  handleClick(e) {
    const clickedTab = e.currentTarget;

    if (e.currentTarget.hasAttribute("aria-disabled")) {
      e.preventDefault();
      return;
    }

    this.tabs.map((tab, i) => {
      if (tab === clickedTab) {
        this.activeTabIndex = i;
        return this.toggleTab(tab, "on");
      }
      return this.toggleTab(tab, "off");
    });

    this.tabPanels.map((tabPanel) => {
      if (
        tabPanel.getAttribute("id") === clickedTab.getAttribute("aria-controls")
      ) {
        return this.toggleTabPanel(tabPanel, "on");
      }
      return this.toggleTabPanel(tabPanel, "off");
    });

    this.config.onChange(this.activeTabIndex);
  }

  toggleTab(el, state) {
    const isActive = el.classList.contains(this.config.activeClass);

    if (state === "on" && !isActive) {
      el.classList.add(this.config.activeClass);
      el.setAttribute("aria-selected", "true");
      el.setAttribute("tabindex", "0");
    }

    if (state === "off" && isActive) {
      el.classList.remove(this.config.activeClass);
      el.setAttribute("aria-selected", "false");
      el.setAttribute("tabindex", "-1");
    }
  }

  toggleTabPanel(el, state) {
    const isActive = el.classList.contains(this.config.activeClass);

    if (state === "on" && !isActive) {
      el.classList.add(this.config.activeClass);
    }

    if (state === "off" && isActive) {
      el.classList.remove(this.config.activeClass);
    }
  }

  activateNthTab(index) {
    this.tabs.map((tab, i) => {
      if (i === index) {
        this.activeTabIndex = i;
        return this.toggleTab(tab, "on");
      }
      return this.toggleTab(tab, "off");
    });

    this.tabPanels.map((tabPanel, i) => {
      if (i === index) {
        return this.toggleTabPanel(tabPanel, "on");
      }
      return this.toggleTabPanel(tabPanel, "off");
    });
  }

  handleTabFocus() {
    if (!this.rovingTabindex.isActive) {
      this.rovingTabindex.init();
    }
  }
}
